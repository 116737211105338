import React, { Component } from "react";
import { API } from "aws-amplify";
import {
  Tabs,
  Tab,
  Button,
  Alert,
  ProgressBar,
  Grid,
  Row,
  Col,
  Table,
  Label,
  FormGroup,
  FormControl,
  ControlLabel,
  ListGroup,
  ListGroupItem,
  HelpBlock,
  Modal
} from 'react-bootstrap';

import { Card } from "components/Card/Card.jsx";

class DeviceDetail extends Component {
  constructor(props) {
    super(props);

    // General
    this.goDeviceRegistration = this.goDeviceRegistration.bind(this);

    // Commands
    this.handleCommandStatusChange = this.handleCommandStatusChange.bind(this);
    this.handleCommandSubmit = this.handleCommandSubmit.bind(this);
    this.handleCommandDetailShow = this.handleCommandDetailShow.bind(this);
    this.handleCommandDetailClose = this.handleCommandDetailClose.bind(this);
    this.handleCreateCommand = this.handleCreateCommand.bind(this);
    this.handleCommandClose = this.handleCommandClose.bind(this);
    this.handleTargetTemperatureChange = this.handleTargetTemperatureChange.bind(this);
      this.handleManageContainerNameChange = this.handleManageContainerNameChange.bind(this);
      this.handleManageContainerURLChange = this.handleManageContainerURLChange.bind(this);
      this.handleBoxSetCommandParameterValueChange = this.handleBoxSetCommandParameterValueChange.bind(this);
//      this.handleGetContainerConfigNameChange = this.handleGetContainerConfigNameChange.bind(this);
    this.handleSafetyQueryContainerNameChange = this.handleSafetyQueryContainerNameChange.bind(this);
    this.handleBoxGetCommandStatusChange = this.handleBoxGetCommandStatusChange.bind(this);
    this.handleBoxSetCommandStatusChange = this.handleBoxSetCommandStatusChange.bind(this);
    this.handleContainerGetCommandStatusChange = this.handleContainerGetCommandStatusChange.bind(this);
    this.handleContainerSetCommandStatusChange = this.handleContainerSetCommandStatusChange.bind(this);

    // Logs
    this.handleEventLogsSubmit = this.handleEventLogsSubmit.bind(this);
    this.handleEventTypeChange = this.handleEventTypeChange.bind(this);

    // Common
    this.goBack = this.goBack.bind(this);
    this.refresh = this.refresh.bind(this);
    this.clearResponse = this.clearResponse.bind(this);
      this.handleTabSelect = this.handleTabSelect.bind(this);
    //this.commandId = '';

    // Sets up initial state
    this.state = {
      // common
      statusInitial: true,
      page: 'general',

      // general
      loadingDevice: false,
      loadingStatus: false,
      device: false,
      deviceStatus: false,
      deviceError: false,
      statusError: false,
      title: '',
      isMinimized: false,

      // commands
      manageContainerName: '',
      manageContainerURL: '',
      boxSetCommandParameterValue: '',
      containerSetCommandParameterValue: '',

//      getContainerConfigName: '',
      safetyQueryContainerName: '',
        meta: '',
      commandId: null,
      boxGetCommands: this.boxGetCommands,
      boxGetCommand: 'interface_doc',
      boxSetCommands: this.boxSetCommands,
      boxSetCommand: 'reset',
      containerGetCommands: this.containerGetCommands,
      containerGetCommand: 'exists',
      containerSetCommands: this.containerSetCommands,
      containerSetCommand: 'reset',


      //
      actualTemperature: '',
      targetTemperature: '',
      updatedTargetTemperature: '',
      targetTemperatureState: null,
      setCommand: '',
      setCommandValue: '',
      powerStatus: '',
      commandShow: false,
      commandMessage: '',
      showCommandHelpBlock: false,
      commadError: false,
      commandHasMore: true,
      loadingCommand: false,
      creatingCommand: false,
      commandDetail: false,
      commandDetailMeta: false,
      commandDetailError: false,
      commandDetailLoading: false,
      commandLastevalkey: null,
      commands: [],
      commandDetailShow: false,
      commandInitial: true,
      commandStatus: '',
      updatedCommandStatus: '',

      // logs
      eventLogsError: false,
      loadingEventLogs: false,
      eventLogsHasMore: true,
      events: [],
      eventLogsLastevalkey: null,
      eventType: '',
      updatedEventType: '',
      eventDetailShow: false,
      eventDetail: false,
      eventDetailError: false,
      eventDetailLoading: false,
      eventLogsInitial: true,
    };
  }

  componentDidMount() {
    this.handleResize();
    this.getDevice();
      this.getDeviceStatus();
      this.getCommandStatus();


    this.timer = setInterval(async () => {
      await this.getDeviceStatus();
    }, 60000); // Gets status every 1 minute

    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleResize);
  }

  // Handles tab select
  handleTabSelect(eventKey) {
    if (eventKey === 'commands' && this.state.commandInitial) {
      this.setState({ commandInitial: false });
      this.getCommands();
    } else if (eventKey === 'micaContainerCommands' && this.state.commandInitial) {
      this.setState({ commandInitial: false });
      this.getCommands();
    } else if (eventKey === 'micaBoxCommands' && this.state.commandInitial) {
      this.setState({ commandInitial: false });
      this.getCommands();
    } else if (eventKey === 'micaClientCommands' && this.state.commandInitial) {
      this.setState({ commandInitial: false });
      this.getCommands();
    } else if (eventKey === 'commandLogs' && this.state.commandInitial) {
      this.setState({ commandInitial: false });
      this.getCommands();
    } else if (eventKey === 'logs' && this.state.eventLogsInitial) {
      this.setState({ eventLogsInitial: false });
      this.getEventLogs();
    }


    
    this.setState({ page: eventKey });
  }

  // Handles scroll down to load more
  handleScroll = (_event) => {
    let page = this.state.page;
    if (page === 'commands') {
      const {commandError, loadingCommand, commandHasMore} = this.state;
      if (commandError || loadingCommand || !commandHasMore) return;

      // Checks that the page has scrolled to the bottom
      if (this.props.isScrollBottom() && !this.state.commandInitial) {
        this.getCommands();
      }
    } else if (page === 'micaBoxCommands' || page === 'micaContainerCommands' || page === 'micaClientCommands'  || page === 'commandLogs') {
      const {commandError, loadingCommand, commandHasMore} = this.state;
      if (commandError || loadingCommand || !commandHasMore) return;

      // Checks that the page has scrolled to the bottom
      if (this.props.isScrollBottom() && !this.state.commandInitial) {
        this.getCommands();
      }
    } else if (page === 'logs') {
      const {eventLogsError, loadingEventLogs, eventLogsHasMore} = this.state;
      if (eventLogsError || loadingEventLogs || !eventLogsHasMore) return;

      // Checks that the page has scrolled to the bottom
      if (this.props.isScrollBottom() && !this.state.eventLogsInitial) {
        this.getEventLogs();
      }
    }
  };

  // Handles window resize
  handleResize = (_event) => {
    if (window.innerWidth < 993) {
      this.setState({ isMinimized: true, });
    } else {
      this.setState({ isMinimized: false, });
    }
  };

  // Goes to the device registration instruction
  goDeviceRegistration = (deviceId) => {
    this.props.history.push({
      pathname: '/devices/registration',
      state: { deviceId: deviceId }
    });
  }

  // Handles command status change
  handleCommandStatusChange = (event) => {
    this.setState({ updatedCommandStatus: event.target.value});
  }

  // Handles command submit
  handleCommandSubmit = async (event) => {
    event.preventDefault();
    this.setState({
      commands: [],
      commandLastevalkey: null,
      commandError: false,
      commandStatus: this.state.updatedCommandStatus,
    });
    this.getCommands();
  }

  // Handles command detail
  handleCommandDetailShow = async (deviceId, commandId) => {
    this.setState({
      commandDetailError: false,
      commandDetail: false,
      commandDetailLoading: true
    });
    let token = await this.props.getToken();
    let apiName = 'smart-product-api';
    let path = `devices/${deviceId}/commands/${commandId}`;
    let params = {
      headers: {
        'Authorization': token,
      },
      response: true
    };

    API.get(apiName, path, params)
        .then(response => {
            this.setState({ commandDetail: response.data});
            if (response.data.meta != null) {
                this.setState({ commandDetailMeta: JSON.parse(response.data.meta) });
                //this.props.handleNotification(response.data.meta, 'error', 'pe-7s-check', 5);
                //var test = JSON.parse(response.data.commandDetailMeta);
                //this.props.handleNotification(JSON.stringify(this.state.commandDetailMeta, null, 2), 'error', 'pe-7s-check', 5);
            }
      })
      .catch(error => {
        let message = error.response;
        if (message === undefined) {
          message = error.message;
        } else {
          message = error.response.data.message;
        }

        this.setState({ commandDetailError: message });
      })
      .finally(() => {
        this.setState({ commandDetailLoading: false });
      })
      this.setState({ commandDetailShow: true });
  }

  // Handles command detail close
  handleCommandDetailClose = () => {
    this.setState({ commandDetailShow: false });
  }

  // Handles create command
  handleCreateCommand = (mode) => {
    let pass = true;
    let message = '';
    let setCommand = '';
    let setCommandValue = '';

    switch (mode) {
      case 'HEAT': {
        message = 'turn on the heat';
        setCommand = 'set-mode';
        setCommandValue = 'HEAT';
        break;
      }
      case 'AC': {
        message = 'turn on the AC';
        setCommand = 'set-mode';
        setCommandValue = 'AC';
        break;
      }
      case 'OFF': {
        message = 'turn off the device';
        setCommand = 'set-mode';
        setCommandValue = 'OFF';
        break;
      }
      case 'TEMPERATURE': {
        if (!this.targetTemperatureValidate()) {
          pass = false;
          message = 'Invalid target temperature. (50 <= temperature <= 110)';
        } else if (this.state.targetTemperature === this.state.updatedTargetTemperature) {
          pass = false;
          message = 'Target temperature has not been changed.';
        } else {
          message = `set the temperature to ${this.state.updatedTargetTemperature}`;
          setCommand = 'set-temp';
          setCommandValue = this.state.updatedTargetTemperature;
        }
        break;
      }
      case 'STARTCONTAINER':
      case 'STOPCONTAINER':
      case 'DELETECONTAINER':
            {
            if (this.state.manageContainerName === '') {
            pass = false;
            message = 'Container name must be specified.';
        } else {
            switch (mode) {
                case 'STARTCONTAINER':
                    message = `Start container ${this.state.manageContainerName}`;
                    setCommand = 'start-container';
                    break;
                case 'STOPCONTAINER':
                    message = `Stop container ${this.state.manageContainerName}`;
                    setCommand = 'stop-container';
                    break;
                case 'DELETECONTAINER':
                    message = `Delete container ${this.state.manageContainerName}`;
                    setCommand = 'delete-container';
                    break;
                default: {
                    break;
                }
            }
            setCommandValue = this.state.manageContainerName;
        }
        break;
    }
      case 'INSTALLCONTAINER':
          {
              if (this.state.manageContainerName === '') {
                  pass = false;
                  message = 'Container name must be specified.';
                  break;
              }
              if (this.state.manageContainerURL === '') {
                  pass = false;
                  message = 'Container URL must be specified.';
              } else {
                  message = `Install container ${this.state.manageContainerName}`;
                  setCommand = 'install-container';
                  setCommandValue = JSON.stringify({ 'name': this.state.manageContainerName, 'url': this.state.manageContainerURL}) ;
                }
              break;
          }
      case 'GETCONTAINERCONFIG': {
          if (this.state.manageContainerName === '') {
              pass = false;
              message = 'Container name must be specified.';
          } else {
              message = `Get config for container ${this.state.manageContainerName}`;
              setCommand = 'get-container-config';
              setCommandValue = this.state.manageContainerName;
          }
          break;
      }
      case 'BOXGETCOMMAND': {
        if (this.state.boxGetCommand === '') {
            pass = false;
            message = 'Command must be selected.';
        } else {
            message = `Perform box Get command ${this.state.boxGetCommand}`;
            setCommand = 'get_base';
            setCommandValue =  this.state.boxGetCommand;
        }
        break;
      }
      case 'BOXSETCOMMAND': {
        var needsParam = this.state.boxSetCommand !== 'reset' && this.state.boxSetCommand !== 'reboot' && this.state.boxSetCommand !== 'cert_reset';
        if (this.state.boxSetCommand === '') {
            pass = false;
            message = 'Command must be selected.';
        } else if(needsParam  && this.state.boxSetCommandParameterValue === '') {
          pass = false;
          message = 'Parameter value must be specified.';
        } else {
          message = `Perform box Set command ${this.state.boxSetCommand}`;
            setCommand = 'set_base';
            setCommandValue =  this.state.boxSetCommand;
        }
        break;
    }
  case 'GETCLIENTSCOMMAND': {
          message = `Perform Get Clients command`;
          setCommand = 'get_clients';
          setCommandValue =  'get_clients';
      break;
  }
  case 'CONTAINERGETCOMMAND': {
    if (this.state.containerGetCommand === '') {
        pass = false;
        message = 'Command must be selected.';
      } else if(this.state.manageContainerName === '') {
        pass = false;
        message = 'Container name must be specified.';
      } else {
        message = `Perform Get command  ${this.state.containerGetCommand} for ${this.state.manageContainerName}`;
        setCommand = 'get_container';
        setCommandValue =  this.state.containerGetCommand;
    }
    break;
}
case 'CONTAINERSETCOMMAND': {
    var needsContainerSetParam = this.state.containerSetCommand !== 'delete' && this.state.containerSetCommand !== 'reset' && this.state.containerSetCommand !== 'start' && this.state.containerSetCommand !== 'stop' && this.state.containerSetCommand !== 'merge';
    if (this.state.containerSetCommand === '') {
        pass = false;
        message = 'Command must be selected.';
    } else if(this.state.manageContainerName === '') {
      pass = false;
      message = 'Container name must be specified.';
    } else if(needsContainerSetParam  && this.state.containerSetCommandParameterValue === '') {
      pass = false;
      message = 'Parameter value must be specified.';
    } else {
      message = `Perform Set command  ${this.state.containerSetCommand} for ${this.state.manageContainerName}`;
      setCommand = 'set_container';
        setCommandValue =  this.state.containerSetCommand;
    }
    break;
}
default: {
        break;
      }
    }

    if (pass) {
      this.setState({
        setCommand: setCommand,
        setCommandValue: setCommandValue,
        commandShow: true,
        message: message,
      }, () => {});
    } else {
      this.props.handleNotification(message, 'error', 'pe-7s-check', 5);
    }
  }



  // helper
  formatJson = (json) => {
    if(this.state.meta.length > 2){
      var jsonData = JSON.parse(this.state.meta);
      var dat = JSON.stringify(jsonData, null, 2);
      return dat;
    }
    return "";
  }

  // Handles command pop up close
  handleCommandClose = () => {
    this.setState({ commandShow: false });
  }

  // Handles target temperature change
  handleTargetTemperatureChange = (event) => {
    this.setState({ updatedTargetTemperature: event.target.value }, () => {
      this.targetTemperatureValidate();
    });
    }

    handleManageContainerNameChange = (event) => {
        this.setState({ manageContainerName: event.target.value });
    }

    handleManageContainerURLChange = (event) => {
      this.setState({ manageContainerURL: event.target.value });
  }

    handleBoxSetCommandParameterValueChange = (event) => {
        this.setState({ boxSetCommandParameterValue: event.target.value });
    }
        

//    handleGetContainerConfigNameChange = (event) => {
//        this.setState({ getContainerConfigName: event.target.value });
//    }
    handleSafetyQueryContainerNameChange = (event) => {
      this.setState({ safetyQueryContainerName: event.target.value });
  }
  
  handleBoxGetCommandStatusChange = (event) => {
    //this.setState({ updatedCommandStatus: event.target.value});
    this.setState({ boxGetCommand: event.target.value });
    // this.props.handleNotification(event.target.value, 'warning', 'pe-7s-check', 2);
  }

  handleBoxSetCommandStatusChange = (event) => {
    this.setState({ boxSetCommand: event.target.value });
  }

  handleContainerGetCommandStatusChange = (event) => {
    this.setState({ containerGetCommand: event.target.value });
  }

  handleContainerSetCommandStatusChange = (event) => {
    this.setState({ containerSetCommand: event.target.value });
  }


// other commands
boxGetCommands = [
  {name:"interface_doc", description:"Documentation of the MICA JSON RPC interface"}, 
  {name:"datetime", description:"Date and time"}, 
  {name:"tz_list", description:"Available timezones"}, 
  {name:"user_settings", description:"Settings given by the user"}, 


  {name:"service_version", description:"Version of MICA JSON RPC interface"}, 
  {name:"available_userspace", description:"Remaining user flash memory"}, 
  {name:"name", description:"Hostname of MICA"}, 
  {name:"system_version", description:"Version of MICA Firmware"}, 
  {name:"info_text", description:"Text shown on Information Web GUI page"}, 
  {name:"system_summary", description:"Complete system summary including device label information"}, 
  {name:"storage_info", description:"Remaining user flash memory"}, 
  {name:"recovery_info", description:"List of containers whose container config was broken"}, 
  {name:"has_wlan", description:"MICA is wireless MICA or not"}, 
  {name:"wlan", description:"Wireless configuration"}, 
  {name:"device_info", description:"Meta information about a linux device that can be given to a container, eg. major number, pass a identity - parameter as well to identify the according device (eg. /dev/ttyUSB0)"}, 
  {name:"available_devices", description:"List of all devices that can be given to a container"}, 
  {name:"settings.system", description:"All system settings, if no subselector is given. Use subselectors for more dedicated information, like eg. .settings.system.net for network settings"}, 
  {name:"base_info", description:"Basic information about MICA without SSO login"}, 
]
 
boxSetCommands = [
  {name:"reset", description:"Firmware Reset to default settings, containers will remain untouched"}, 
  {name:"reboot", description:"Restart MICA device"}, 
  {name:"use_ntp", description:"Switch on / off ntp, requires value parameter representing a bool (on = true, off = false)"}, 
  {name:"ntp_list", description:"set NTP servers, requires value parameter representing the ntp server list as comma separated string"}, 
  {name:"timezone", description:"set timezone, requires value parameter representing the timezone as string"}, 
  {name:"mcp_gpio", description:"set MCP GPIO pin (only wireless), requires value parameter representing the mcp configuration as mcp_config JSON object (see Parameter Declaration above)"}, 
  {name:"datetime", description:"set date and time, requires value parameter representing the datetime configuration as datetime JSON object"}, 
  {name:"settings.system.net", description:"set network settings, requires value parameter representing the network config as net_config JSON object"}, 
  {name:"cert", description:"Replace Web certificate, requires value parameter representing the certification file as certfile JSON object"}, 
  {name:"cert_reset", description:"Reset Web certificate to default"}, 
  {name:"ssh", description:"Switch on / off SSH, requires value parameter representing a boolean (on = true, off = false), dev_mode needs to be turned on"}, 
  {name:"wlan", description:"Set Wifi configuration, requires value parameter represented by wlan_config JSON object"}, 
]



containerGetCommands = [
  {name:"exists", description:"Container is installed or not"}, 
  {name:"devices", description:"All linux devices given to the container"}, 
  {name:"state", description:"Current container state"}, 
  {name:"rfs", description:"Triggers an export of the container base root file system"}, 
  {name:"overlay", description:"Triggers an export of the container overlay root file system"}, 
  {name:"storage_info", description:"Disk space usage of both container root file systems base and overlay"}, 
  {name:"vmsettings", description:"Current container settings"}, 
  {name:"metrics", description:"CPU usage and memory consumption, will return information about all containers if name parameter is left blank"}, 
]

containerSetCommands = [
  //{name:"delete", description:"Remove container from MICA"}, 
  {name:"reset", description:"Remove overlay root file system, network settings remain untouched"}, 
  //{name:"start", description:"Start container"}, 
  //{name:"stop", description:"Stop container"}, 
  {name:"merge", description:"Move overlay root file system into base root file system"}, 
  {name:"add_device", description:"Add linux device to container, requires value parameter representing the device name as string, eg. /dev/ttyUSB0"}, 
  {name:"del_device", description:"Delete linux device from container, requires value parameter representing the device name as string, eg. /dev/ttyUSB0"}, 
  {name:"copy", description:"Duplicate container file systems to a new container, requires value parameter representing the duplicated container name as string"}, 
  {name:"readonly", description:"Set permission to delete container, requires value parameter representing the delete flag as bool (deletable = false, not deletable = true)"}, 
  {name:"vmsettings.net", description:"Set container network settings, requires value parameter representing the container network config as cnt_net_config JSON object"}, 
  {name:"update", description:"Replace container base root file system, make sure to upload the new rfs image before (See chapter 'Transferring files' above), requires value parameter representing the filename as string"}, 
  {name:"to_overlay", description:"Replace container overlay root file system, make sure to upload the new rfs image before (See chapter 'Transferring files' above), requires value parameter representing the filename as string"}, 
  {name:"install", description:"Install new container, make sure to upload the universal Tar image before (See chapter 'Transferring files' above), requires value parameter representing the filename as string"}, 
]




  // Handles event logs submit
  handleEventLogsSubmit = async (event) => {
    event.preventDefault();
    this.setState({
      events: [],
      eventLogsLastevalkey: null,
      eventLogsError: false,
      eventType: this.state.updatedEventType,
    });
    this.getEventLogs();
  }

  // Handles input changes
  handleEventTypeChange = (event) => {
    this.setState({ updatedEventType: event.target.value});
  }

  // Handles event detail
  handleEventDetailShow = async (deviceId, eventId) => {
    this.setState({
      eventDetailError: false,
      eventDetail: false,
      eventDetailLoading: true
    });
    let token = await this.props.getToken();
    let apiName = 'smart-product-api';
    let path = `devices/${deviceId}/events/${eventId}`;
    let params = {
      headers: {
        'Authorization': token,
      },
      response: true
    };

    API.get(apiName, path, params)
      .then(response => {
        this.setState({ eventDetail: response.data });
        if (!response.data.ack) {
          this.updateEvent(deviceId, eventId);
        }
      })
      .catch(error => {
        let message = error.response;
        if (message === undefined) {
          message = error.message;
        } else {
          message = error.response.data.message;
        }

        this.setState({ eventDetailError: message });
      })
      .finally(() => {
        this.setState({ eventDetailLoading: false });
      })
      this.setState({ eventDetailShow: true });
  }

  // Handles detail close
  handleEventDetailClose = () => {
    this.setState({ eventDetailShow: false });
  }

  // Gets device information
  getDevice = async () => {
    this.setState({ loadingDevice: true });
    const { deviceId } = this.props.match.params;
    let token = await this.props.getToken();
    let apiName = 'smart-product-api';
    let path = `devices/${deviceId}`;
    let params = {
      headers: {
        'Authorization': token,
      },
      response: true,
    };

    API.get(apiName, path, params)
      .then(response => {
        let device = response.data;
        this.setState({
          device: device,
          title: `${device.deviceName}`,
        });
      })
      .catch(error => {
        let message = error.response;
        if (message === undefined) {
          message = error.message;
        } else {
          message = error.response.data.message;
        }

        this.setState({ deviceError: message })
      })
      .finally(() => {
        this.setState({ loadingDevice: false });
      });
  }

  // Gets device status
  getDeviceStatus = async () => {
    if (this.state.statusInitial) {
      this.setState({ loadingStatus: true });
    }

    const { deviceId } = this.props.match.params;
    let token = await this.props.getToken();
    let apiName = 'smart-product-api';
    let path = `devices/${deviceId}/status`;
    let params = {
      headers: {
        'Authorization': token,
      },
      response: true,
    };


    API.get(apiName, path, params)
      .then(response => {
        let deviceStatus = '';
        if (
          Object.keys(response.data).length === 0
          || response.data.state.reported === undefined
          || !response.data.connected
        ) {
          deviceStatus = {
            state: {},
            connected: false,
          }
          this.setState({
            actualTemperature: 'N/A',
            targetTemperature: 'N/A',
            powerStatus: 'Disconnected',

          }, () => {});
            //this.props.handleNotification('deviceStatus: disconnected', 'error', 'pe-7s-check', 5);
      } else {
            deviceStatus = response.data;


            //this.props.handleNotification('deviceStatus: ' + JSON.stringify(deviceStatus), 'error', 'pe-7s-check', 5);
            //this.props.handleNotification('deviceType: ' + JSON.stringify(response.data.deviceType), 'error', 'pe-7s-check', 5);


          let reported = response.data.state.reported;
          if (this.state.statusInitial) {
            this.setState({
              actualTemperature: reported.actualTemperature,
              targetTemperature: reported.targetTemperature,
              updatedTargetTemperature: reported.targetTemperature,
                powerStatus: reported.powerStatus,
 //               meta: (reported.meta !== null ? JSON.stringify(reported.meta) : null),
            });
          } else {
            this.setState({
                actualTemperature: reported.actualTemperature,
                powerStatus: reported.powerStatus,
//                meta: (reported.meta !== null ? JSON.stringify(reported.meta) : null),
            });
          }
          }
          // crash when details
//          if (response.data.state.reported.meta == null) {
          this.setState({ deviceStatus: deviceStatus });

//          this.setState({ deviceStatus: deviceStatus }, () => { });

//          }
//          this.props.handleNotification('steffi', 'error', 'pe-7s-check', 5);
      })
      .catch(error => {
        let message = error.response;
        if (message !== undefined) {
          if (message.data.error === 'MissingRegistration') {
            // If getting the device status has an issue due to MissingRegistration, clear the timer.
            clearInterval(this.timer);
          } else {
            this.props.handleNotification('Failed to get device status', 'error', 'pe-7s-check', 5);
          }
        }
        
        this.setState({
          actualTemperature: 'N/A',
          targetTemperature: 'N/A',
          powerStatus: 'FAIL',
        });
      })
      .finally(() => {
        if (this.state.statusInitial) {
          this.setState({
            loadingStatus: false,
            statusInitial: false,
          });
        }
      });
  }

    // Gets command status
    getCommandStatus = async () => {
        // this.props.handleNotification('GetCommandInfo1 for ' + this.state.commandId, 'error', 'pe-7s-check', 5);
       if (this.state.statusInitial) {
            this.setState({ loadingStatus: true });
        }

        if (this.state.commandId == null) {
            return;
        }
        const { deviceId } = this.props.match.params;
        let token = await this.props.getToken();
        let apiName = 'smart-product-api';
        let path = `devices/${deviceId}/commands/` + this.state.commandId;
        let params = {
            headers: {
                'Authorization': token,
            },
            response: true,
        };

        this.props.handleNotification('GetCommandInfo for ' + this.state.commandId, 'error', 'pe-7s-check', 5);

        API.get(apiName, path, params)
            .then(response => {
                //this.props.handleNotification('step2', 'error', 'pe-7s-check', 5);
                let commandStatus = '';
                commandStatus = response.data;
                if (commandStatus.meta != null) {
                    this.setState({
                        meta  : commandStatus.meta,
                        commandId: null ,
                  }, () => { });
                    //this.props.handleNotification('commandStatus: ' + JSON.stringify(commandStatus, null, 2), 'error', 'pe-7s-check', 5);
                }

            })
            .catch(error => {
                this.props.handleNotification('error', 'error', 'pe-7s-check', 5);

                let message = error.response;
                if (message !== undefined) {
                    this.props.handleNotification('Failed to get command status ' + message, 'error', 'pe-7s-check', 5);
                }
                
            })
            .finally(() => {
//                this.setState({ commandId: null });

                if (this.state.commandId != null) { // no result received yet, so ask again in 5 seconds
                    this.props.handleNotification('settimeout', 'info', 'pe-7s-check', 5);
                    setTimeout(async () => {
                        await this.getCommandStatus();
                    }, 5000);
                }


            });
    }




  // Gets device commands
  getCommands = async () => {
    this.setState({ loadingCommand: true});
    const { deviceId } = this.props.match.params;
    let token = await this.props.getToken();
    let apiName = 'smart-product-api';
    let path = `devices/${deviceId}/commands`;
    let params = {
      headers: {
        'Authorization': token,
      },
      response: true,
      queryStringParameters: {
        lastevalkey: JSON.stringify(this.state.commandLastevalkey),
        commandStatus: this.state.commandStatus,
      }
    };

    API.get(apiName, path, params)
      .then(response => {
        /**
         * If response has no data and LastEvaluatedKey is null, there is no more data.
         * Otherwise, show the data.
         */
        if (response.data.Items.length === 0
          && (response.data.LastEvaluatedKey === null
            || response.data.LastEvaluatedKey === undefined)) {
          this.setState({
            commandHasMore: false,
            loadingCommand: false
          });
        } else {
          this.setState({
            commandHasMore: response.data.LastEvaluatedKey !== undefined,
            loadingCommand: false,
            commandLastevalkey: response.data.LastEvaluatedKey,
            commandStatus: response.data.commandStatus,
            commands: [
              ...this.state.commands,
              ...response.data.Items
            ]
          });
        }
      }).catch(error => {
        let message = error.response;
        if (message === undefined) {
          message = error.message;
        } else {
          message = error.response.data.message;
        }

        this.setState({
          commandError: message,
          loadingCommand: false
        });
      });
  };

  // Validates target temperature
  targetTemperatureValidate = () => {
    let temperature = this.state.updatedTargetTemperature;
    let pass = !isNaN(temperature) && temperature !== ''
      && temperature >= 50 && temperature <= 110;

    if (!pass) {
      this.setState({
        showHelpBlock: true,
        targetTemperatureState: 'error',
      });
    } else {
      this.setState({
        showHelpBlock: false,
        targetTemperatureState: null,
      });
    }

    return pass;
  }

  // Creates a command
  createCommand = async () => {
    if (!this.state.creatingCommand) {
      this.setState({ creatingCommand: true });
      const { deviceId } = this.props.match.params;
      let {targetTemperature, setCommand, setCommandValue, powerStatus, safetyQueryContainerName} = this.state;
 

      if (setCommand === 'set-temp') {
        targetTemperature = this.state.updatedTargetTemperature;
      }

      if (setCommand === 'set-mode') {
        powerStatus = setCommandValue;
      }

      // delete confirmation
      if (setCommand === 'delete-container') {
        if(safetyQueryContainerName !==  this.state.manageContainerName){
          this.setState({
            commandShow: false,
            creatingCommand: false,
            commands: [],
            commandLastevalkey: null,
            commandError: false,
          });
          this.props.handleNotification('Invalid container name ' + safetyQueryContainerName, 'error', 'pe-7s-check', 5);
          return;
        }
      }

      this.setState({safetyQueryContainerName: ''});

      let body = {
        deviceId: deviceId,
        commandDetails: {
          command: this.state.setCommand,
          value: this.state.setCommandValue,
        },
        shadowDetails: {
          powerStatus: powerStatus,
          actualTemperature: this.state.actualTemperature,
            targetTemperature: targetTemperature,
         }
      }



        // mika
        let micaAction = '';
        let micaSubAction = '';

        switch (setCommand) {
            case 'set-temp':
            case 'set-mode':
                break;
            case 'get-container-config': micaAction = 'getsettings'; break;
            case 'start-container': micaAction = 'start'; break;
            case 'stop-container': micaAction = 'stop'; break;
            case 'delete-container': micaAction = 'delete'; break;
            case 'install-container': micaAction = 'install'; break;
            default:
                micaSubAction = this.state.setCommandValue;
                micaAction = setCommand;
                break;
        }

        if (micaAction.length > 0) {

            let micaContainerName = '';
            //let micaAction = '';
            let micaUser = 'admin';
            let micaPw = 'YWRtaW4=';
            //let micaId = uuidv4();
            let micaUrl = '';
            let micaParameterValue = '';
            /*if (micaAction === 'getsettings') {
                //micaAction = 'getsettings';
                micaContainerName = this.state.getContainerConfigName;
            } else */ {
                micaContainerName = this.state.manageContainerName;
            }
            

            switch (micaAction) {
              case 'set_container':
                micaParameterValue = this.state.containerSetCommandParameterValue;
                break;
              case 'set_base':
                micaParameterValue = this.state.boxSetCommandParameterValue;
                break;
              case 'install':
                micaUrl = this.state.manageContainerURL;
                micaParameterValue = this.state.manageContainerURL;
                break;
              default:
                  break;
            }

//            if (micaAction === 'install') {
//                micaUrl = this.state.manageContainerURL;
//            } 

            var metaData = {
                containerName: micaContainerName,
                action: micaAction,
                subAction: micaSubAction,
                user: micaUser,
                pw: micaPw,
                url: micaUrl,
                parameterValue: micaParameterValue
            }
            //         this.props.handleNotification('has meta', 'error', 'pe-7s-check', 5);

            body.shadowDetails = {
                powerStatus: powerStatus,
                actualTemperature: this.state.actualTemperature,
                targetTemperature: targetTemperature,
                meta: metaData,
            }
        }


 //       if (setCommand === 'get-container-config' || setCommand === 'start-container') {
            
 //           let micaContainerName = '';
 //           let micaAction = '';
 //           let micaUser = 'admin';
 //           let micaPw = 'YWRtaW4=';
 //           //let micaId = uuidv4();
 //           let micaUrl = '';
 //           if (setCommand === 'get-container-config') {
 //               micaAction = 'getsettings';
 //               micaContainerName = this.state.getContainerConfigName;
 //           } else {
 //               micaAction = 'start';
 //               micaContainerName = this.state.manageContainerName;
 //           }

 //           var metaData = {
 //               containerName: micaContainerName,
 //               action: micaAction,
 //               user: micaUser,
 //               pw: micaPw,
 //               url: micaUrl,
 //           }
 ////         this.props.handleNotification('has meta', 'error', 'pe-7s-check', 5);

 //            body.shadowDetails =  {
 //               powerStatus: powerStatus,
 //               actualTemperature: this.state.actualTemperature,
 //                targetTemperature: targetTemperature,
 //                meta : metaData,
 //                //containerName:  micaContainerName,
 //                //action: micaAction,
 //                //user: micaUser,
 //                //pw: micaPw,
 //                //url: micaUrl,
 //           }
 //       }



      let token = await this.props.getToken();
      let apiName = 'smart-product-api';
      let path = `devices/${deviceId}/commands`;
      let params = {
        headers: {
          'Authorization': token,
        },
        body: body,
        response: true,
      };

        this.setState({
            commandId: null
        }, () => { });

        //var commandIdLocal = null;
      API.post(apiName, path, params)
          .then(response => {
              let command = response.data;
             // commandIdLocal = command.commandId;
              //if (command.commandId != null) {
              //    //commandIdLocal = command.commandId;
              //    this.setState({
              //        commandId: command.commandId
              //    }, () => { });
              //}
              //this.props.handleNotification('step0 ', 'error', 'pe-7s-check', 5);
         //   this.props.handleNotification('json: ' + JSON.stringify(command), 'success', 'pe-7s-check', 5);
          this.setState({
            powerStatus: powerStatus,
            targetTemperature: targetTemperature,
            commandId: command.commandId 
          }, () => {});
          this.props.handleNotification('Success to execute the command', 'success', 'pe-7s-check', 5);
        })
        .catch(error => {
          this.props.handleNotification('Failed to execute the command', 'error', 'pe-7s-check', 5);
        })
        .finally(() => {
          this.setState({
            commandShow: false,
            creatingCommand: false,
            commands: [],
            commandLastevalkey: null,
            commandError: false,
          });
          this.getDeviceStatus();
            this.getCommands();
            //this.props.handleNotification('step1 ' + commandIdLocal, 'error', 'pe-7s-check', 5);
            if (this.state.commandId != null) {
              
                //this.props.handleNotification('commandId: ' + this.state.commandId, 'error', 'pe-7s-check', 5);
                this.getCommandStatus();

             }
        });
    } else {
      this.props.handleNotification('Still in progress to execute the command', 'error', 'pe-7s-check', 5);
    }
  }

  // Gets device event log
  getEventLogs = async () => {
    this.setState({ loadingEventLogs: true});
    const { deviceId } = this.props.match.params;
    let token = await this.props.getToken();
    let apiName = 'smart-product-api';
    let path = `devices/${deviceId}/events`;
    let params = {
      headers: {
        'Authorization': token,
      },
      response: true,
      queryStringParameters: {
        lastevalkey: JSON.stringify(this.state.eventLogsLastevalkey),
        eventType: this.state.eventType
      }
    };

    API.get(apiName, path, params)
      .then(response => {
        /**
         * If response has no data and LastEvaluatedKey is null, there is no more data.
         * Otherwise, show the data.
         */
        if (response.data.Items.length === 0
          && (response.data.LastEvaluatedKey === null
            || response.data.LastEvaluatedKey === undefined)) {
          this.setState({
            eventLogsHasMore: false,
            loadingEventLogs: false
          });
        } else {
          this.setState({
            eventLogsHasMore: response.data.LastEvaluatedKey !== undefined,
            loadingEventLogs: false,
            eventLogsLastevalkey: response.data.LastEvaluatedKey,
            eventType: response.data.eventType,
            events: [
              ...this.state.events,
              ...response.data.Items
            ]
          });
        }
      }).catch(error => {
        let message = error.response;
        if (message === undefined) {
          message = error.message;
        } else {
          message = error.response.data.message;
        }

        this.setState({
          eventLogsError: message,
          loadingEventLogs: false
        });
      });
  }

  // Updates the event
  updateEvent = async (deviceId, eventId) => {
    let token = await this.props.getToken();
    let apiName = 'smart-product-api';
    let path = `devices/${deviceId}/events/${eventId}`;
    let params = {
      headers: {
        'Authorization': token,
      },
      body: {
        id: eventId,
        ack: true,
        suppress: true,
      },
    };

    API.put(apiName, path, params)
      .catch(error => {
        let message = error.response;
        if (message === undefined) {
          message = error.message;
        } else {
          message = error.response.data.message;
        }

        this.setState({ eventDetailError: message });
      });
  }

  // Goes back to the main landing page
  goBack() {
    this.props.history.push('/devices');
    }

refresh() {
    this.getDeviceStatus();
    this.getCommandStatus();
}

clearResponse() {
  this.setState({ meta: '' });
}

  render() {
    const { loadingDevice, loadingStatus, device, deviceStatus, 
      loadingCommand, commandDetailLoading, creatingCommand, commands, commandStatus, powerStatus, commandDetail, 
      loadingEventLogs, eventDetailLoading, events, eventType, eventDetail,
      commandHasMore, eventLogsHasMore, showCommandHelpBlock,
        deviceError, statusError, commandError, commandDetailError, commandDetailMeta, eventLogsError, eventDetailError,
        title, message, isMinimized, setCommand, 
        boxGetCommands, boxGetCommand, boxSetCommands, boxSetCommand,
        containerGetCommands, containerGetCommand, containerSetCommands, containerSetCommand 
      } = this.state;
    const commandThArray = ['Command Detail', 'Command Status', 'Created At', 'Updated At'];
    const disabledConditions = ['FAIL', 'Disconnected'];
    const eventThArray = ['Event Message', 'Event Type', 'Created At'];

    return(
      <div className="content">
        <Grid fluid>
        { !loadingDevice &&
          <div key="main-content">
            <Row>
              <Col md={12}>
                  <div key="device-name">
                    <h4>
                      Device Information <br />
                      <Label bsStyle={device.status === 'deleted' ? "danger" : "primary"}>{title}</Label>
                      <Button bsSize="small" className="btn-fill pull-right" onClick={this.goBack}>&lt;&lt;</Button>
                      &nbsp;&nbsp;
                      <Button bsSize="small" className="btn-fill pull-right" onClick={this.refresh}>Refresh</Button>
                    </h4>
                  </div>
              </Col>
            </Row>
            { device &&
            <Row>
              <Col md={12}>
                <Tabs defaultActiveKey={"general"} animation={false} id="device-detail-tab" 
                  onSelect={k => this.handleTabSelect(k)} className={ isMinimized ? "mobile_tabs" : "" }>
{/*
  General Tab
*/}
                  <Tab eventKey={"general"} title="General">
                    <Row>
                      <Col md={6}>
                        { device && deviceStatus &&
                        <Card title="Device Detail"
                          content={
                            <div>
                            <Table striped bordered>
                              <tbody>
                              <tr>
                                  <td>Serial Number</td>
                                  <td>{device.deviceId}</td>
                                </tr>
                                <tr>
                                  <td>Device type</td>
                                  <td>{deviceStatus.deviceType}</td>
                                </tr>
                                <tr>
                                  <td>Created At</td>
                                  <td>{device.createdAt}</td>
                                </tr>
                                <tr>
                                  <td>Activated At</td>
                                  <td>{device.activatedAt}</td>
                                </tr>
                                <tr>
                                  <td>Updated At</td>
                                  <td>{device.updatedAt}</td>
                                </tr>
                                <tr>
                                  <td>Status</td>
                                  <td>
                                    <Label bsStyle={device.status === 'deleted' ? "danger" : "info"}>{device.status}</Label>
                                    { device.status === 'pending' &&
                                    <div>
                                      <br />
                                      <Button className="btn-fill" bsSize="xsmall" onClick={() => this.goDeviceRegistration(device.deviceId)}>Back to Registration</Button>
                                    </div>
                                    }
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                            <Table striped bordered>
                              <tbody>
                                <tr>
                                  <td colSpan="2"><b>Details</b></td>
                                </tr>
                                { device.details &&
                                  Object.keys(device.details).map(key => {
                                    return(
                                      <tr key={key}>
                                        <td>{key}</td>
                                        <td>{device.details[key]}</td>
                                      </tr>
                                    )
                                  })
                                }
                                { (!device.details || 
                                  Object.keys(device.details).length === 0) &&
                                  <tr>
                                    <td>Device Details</td>
                                    <td>Not found</td>
                                  </tr>
                                }
                              </tbody>
                            </Table>
                            </div>
                          }
                        />
                        }
                      </Col>
                      <Col md={6}>
                        { device && deviceStatus &&
                        <Card
                          title="Device Status"
                          content={
                            <div>
                            <Table striped bordered>
                              <tbody>
                                <tr>
                                  <td key="connectivity">Connectivitiy</td>
                                  <td>{deviceStatus.connected ? "Connected" : "Disconnected"}</td>
                                </tr>
                              </tbody>
                            </Table>
                            <Table striped bordered>
                              <tbody>
                                <tr>
                                  <td colSpan="2"><b>State</b></td>
                                </tr>
                                { Object.keys(deviceStatus.state).length !== 0 &&
                                  Object.keys(deviceStatus.state.reported).map(key => {
                                    return(
                                      <tr key={key}>
                                        <td>{key}</td>
                                        <td>{deviceStatus.state.reported[key]}</td>
                                      </tr>
                                    )
                                  })
                                }
                                { Object.keys(deviceStatus.state).length === 0 &&
                                  <tr>
                                    <td>State</td>
                                    <td>Not found</td>
                                  </tr>
                                }
                              </tbody>
                            </Table>
                            </div>
                          }
                        />
                        }
                      </Col>
                    </Row>
                  </Tab>

{/*
  Mica Box Commands Tab
*/}
                                <Tab eventKey={"micaBoxCommands"} title="MICA Box">
                                    <Row>
                                        <Col md={12}>
                                            <Card
                                                content={
                                                    <div>
                                                        <p>Mica Box Commands</p>
                                                        <ListGroup>

                                                            <ListGroupItem>
                                                                <h3>Set Commands</h3>
                                                                <Col md={6}>
                                                                  <FormGroup>
                                                                      <ControlLabel>Command</ControlLabel>
                                                                      <FormControl ng-model="boxSetCommands" componentClass="select" 
                                                                        onChange={this.handleBoxSetCommandStatusChange} defaultValue={boxGetCommand}>
                                                                      {
                                                                            boxSetCommands.map(command => {
                                                                          return (
                                                                            <option value={command.name}>{command.name}</option>
                                                                          );
                                                                        })
                                                                      }
                                                                      </FormControl>
                                                                  </FormGroup>
                                                                </Col>
                                                                <div className="clearfix" />
                                                                  <p class="info" >&nbsp;&nbsp;&nbsp; { 
                                                                    boxSetCommands.map(command => {
                                                                      return ( command.name == boxSetCommand ? command.description : '');
                                                                    })}
                                                                  </p><br/>
                                                                  <div className="clearfix" />

                                                                <Col md={6}>
                                                                    <FormGroup>
                                                                        <ControlLabel>Value parameter</ControlLabel>
                                                                        <FormControl type="text" defaultValue={this.state.boxSetCommandParameterValue}
                                                                            disabled={disabledConditions.indexOf(powerStatus) > -1}
                                                                            onChange={this.handleBoxSetCommandParameterValueChange} />
                                                                        {showCommandHelpBlock &&
                                                                            <HelpBlock>Invalid value</HelpBlock>
                                                                        }
                                                                    </FormGroup>
                                                                </Col>
                                                                <span className="pull-right">&nbsp;</span>
                                                                <Button bsStyle="warning" bsSize="small" className="btn-fill pull-right"
                                                                    onClick={() => this.handleCreateCommand('BOXSETCOMMAND')}
                                                                    disabled={disabledConditions.indexOf(powerStatus) > -1}
                                                                    active={disabledConditions.indexOf(powerStatus) < 0}>Set</Button>
                                                                <div className="clearfix" />
                                                            </ListGroupItem>

                                                            <ListGroupItem>
                                                                <h3>Get commands</h3>

                                                                <Col md={6}>
                                                                  <FormGroup>
                                                                    <ControlLabel>Command</ControlLabel>
                                                                    <FormControl ng-model="boxGetCommands" componentClass="select" 
                                                                      onChange={this.handleBoxGetCommandStatusChange} defaultValue={boxGetCommand}>
                                                                    {
                                                                          boxGetCommands.map(command => {
                                                                        return (
                                                                          <option value={command.name}>{command.name}</option>
                                                                        );
                                                                      })
                                                                    }
                                                                    </FormControl>
                                                                  </FormGroup>
                                                                </Col>

                                                                <span className="pull-right">&nbsp;</span>
                                                                <Button bsStyle="warning" bsSize="small" className="btn-fill pull-right"
                                                                onClick={() => this.handleCreateCommand('BOXGETCOMMAND')}
                                                                disabled={disabledConditions.indexOf(powerStatus) > -1}
                                                                active={disabledConditions.indexOf(powerStatus) < 0}>Get</Button>
                                                                <div className="clearfix" />
                                                                  <p class="info" >&nbsp;&nbsp;&nbsp; { 
                                                                    boxGetCommands.map(command => {
                                                                      return ( command.name == boxGetCommand ? command.description : '');
                                                                    })}
                                                                  </p>

                                                            </ListGroupItem>

                                                            <ListGroupItem>
                                                                <Col md={12}>
                                                                    <FormGroup>
                                                                        <ControlLabel>Response</ControlLabel>
                                                                        <Button bsSize="small" className="btn-fill pull-right" onClick={this.clearResponse}>Clear</Button>

                                                                    </FormGroup>
                                                                </Col>
                                                                <pre>{  this.formatJson(this.state.meta)} </pre>
                                                                <div className="clearfix" />
                                                            </ListGroupItem>
                                                        </ListGroup>
                                                        
                                                    </div>
                                                }
                                            />



                                        </Col>
                                    </Row>
                                    <Row>
                                      <Col md={12}>
                                        <Card title="Command Log"
                                          content={
                                            <form onSubmit={this.handleCommandSubmit}>
                                              <Col md={12}>
                                                <FormGroup>
                                                  <ControlLabel>Search by Command Status</ControlLabel>
                                                  <FormControl componentClass="select" placeholder={commandStatus}
                                                    onChange={this.handleCommandStatusChange} defaultValue={commandStatus}>
                                                    <option value="">All</option>
                                                    <option value="success">Success</option>
                                                    <option value="failed">Failed</option>
                                                    <option value="pending">Pending</option>
                                                  </FormControl>
                                                </FormGroup>
                                              </Col>
                                              <Button bsStyle="warning" bsSize="small" className="btn-fill pull-right" active type="submit">Search</Button>
                                              <div className="clearfix" />
                                            </form>
                                          }
                                        />
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col md={12}>
                                        { !isMinimized &&
                                        <Card
                                          ctTableFullWidth
                                          ctTableResponsive
                                          content={
                                            <Table striped hover>
                                              <thead>
                                                <tr>
                                                  {
                                                    commandThArray.map((prop, key) => {
                                                      return (
                                                        <th key={key}>{prop}</th>
                                                      );
                                                    })
                                                  }
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {/* If there is no command, showing no command found */}
                                                { commands.length === 0 && !loadingCommand && !commandHasMore &&
                                                  <tr>
                                                    <td colSpan="4" align="center">No command found.</td>
                                                  </tr>
                                                }
                                                { commands.map(command => {
                                                    return (
                                                      <tr key={command.commandId}>
                                                        <td key={command.commandId}>
                                                          <Button bsStyle="info" bsSize="small" className="btn-fill btn-round"
                                                              onClick={() => this.handleCommandDetailShow(command.deviceId, command.commandId)}>
                                                              {command.details.command} / {command.details.value}
                                                          </Button>                                          
                                                        </td>
                                                        <td key={command.status+command.commandId}>
                                                        { command.status === 'success' &&
                                                          <Label bsStyle="info">{command.status}</Label>
                                                        }
                                                        { command.status === 'failed' &&
                                                          <Label bsStyle="danger">{command.status}</Label>
                                                        }
                                                        { command.status === 'pending' &&
                                                          <Label>{command.status}</Label>
                                                        }
                                                        </td>
                                                        <td key={"createdAt"+command.commandId}>{command.createdAt}</td>
                                                        <td key={"updatedAt"+command.commandId}>{command.updatedAt}</td>
                                                      </tr>
                                                    );
                                                  })
                                                }
                                              </tbody>
                                            </Table>
                                          }
                                        />
                                        }
                                        { isMinimized &&
                                        <Card
                                          ctTableFullWidth
                                          ctTableResponsive
                                          content={
                                            <div className="custom_div">
                                            {/* If there is no command, showing no command found */}
                                            { commands.length === 0 && !loadingCommand && !commandHasMore &&
                                              <div className="custom_list_item">
                                                <span className="no_result">No command found.</span>
                                              </div>
                                            }
                                            { commands.map(command => {
                                                return (
                                                  <div className="custom_list_item" key={command.commandId} 
                                                    onClick={() => this.handleCommandDetailShow(command.deviceId, command.commandId)}>
                                                    <span className="custom_mobile_date">
                                                      <span className="mobile_date">C: {this.props.handleDateSize(command.createdAt)}</span>
                                                    </span>                                    
                                                    { command.status === 'success' &&
                                                      <Label bsStyle="info" className="custom_mobile_message">{command.status}</Label>
                                                    }
                                                    { command.status === 'failed' &&
                                                      <Label bsStyle="danger"className="custom_mobile_message">{command.status}</Label>
                                                    }
                                                    { command.status === 'pending' &&
                                                      <Label className="custom_mobile_message">{command.status}</Label>
                                                    }
                                                    <span className="custom_mobile_date">
                                                      <span className="mobile_date">U: {this.props.handleDateSize(command.updatedAt)}</span>
                                                    </span>
                                                    <span className="custom_mobile_title">
                                                      <span className="mobile_title">{command.details.command} / {command.details.value}</span>
                                                    </span>                                    
                                                  </div>
                                                );
                                              })
                                            }
                                            </div>
                                          }
                                        />
                                        }
                                      </Col>
                                    </Row>
                                    { loadingCommand &&
                                    <Row>
                                      <Col md={12}>
                                        <div>
                                          <ProgressBar active now={50} />
                                        </div>
                                      </Col>
                                    </Row>
                                  }
                                  { commandError &&
                                    <Row>
                                      <Col md={12}>
                                        <Alert bsStyle="danger">
                                          <span>{this.state.commandError}</span>
                                        </Alert>
                                      </Col>
                                    </Row>
                                  }

                                </Tab>

{/*
  Mica Container Commands Tab
*/}
                                <Tab eventKey={"micaContainerCommands"} title="MICA Container">
                                    <Row>
                                        <Col md={12}>
                                            <Card
                                                content={
                                                    <div>
                                                        <p>Container</p>
                                                        <ListGroup>

                                                        <ListGroupItem>
                                                                <h3>Container Name</h3>
                                                                <Col md={6}>
                                                                    <FormGroup>
                                                                        <ControlLabel>Name</ControlLabel>
                                                                        <FormControl type="text" defaultValue={this.state.manageContainerName}
                                                                            disabled={disabledConditions.indexOf(powerStatus) > -1}
                                                                            onChange={this.handleManageContainerNameChange} />
                                                                        {showCommandHelpBlock &&
                                                                            <HelpBlock>Invalid value</HelpBlock>
                                                                        }
                                                                    </FormGroup>
                                                                </Col>
                                                                <div className="clearfix" />
                                                            </ListGroupItem>



                                                            <ListGroupItem>
                                                                <h3>Favorite commands</h3>
                                                                <Button bsStyle="warning" bsSize="small" className="btn-fill pull-right"
                                                                    onClick={() => this.handleCreateCommand('GETCONTAINERCONFIG')}
                                                                    disabled={disabledConditions.indexOf(powerStatus) > -1}
                                                                    active={disabledConditions.indexOf(powerStatus) < 0}>Get configuration</Button>
                                                                <span className="pull-right">&nbsp;</span>
                                                                <Button bsStyle="warning" bsSize="small" className="btn-fill pull-right"
                                                                    onClick={() => this.handleCreateCommand('STARTCONTAINER')}
                                                                    disabled={disabledConditions.indexOf(powerStatus) > -1}
                                                                    active={disabledConditions.indexOf(powerStatus) < 0}>Start Container</Button>
                                                                <span className="pull-right">&nbsp;</span>
                                                                <Button bsStyle="warning" bsSize="small" className="btn-fill pull-right"
                                                                    onClick={() => this.handleCreateCommand('STOPCONTAINER')}
                                                                    disabled={disabledConditions.indexOf(powerStatus) > -1}
                                                                    active={disabledConditions.indexOf(powerStatus) < 0}>Stop Container</Button>
                                                                <span className="pull-right">&nbsp;</span>
                                                                <Button bsStyle="danger" bsSize="small" className="btn-fill pull-right"
                                                                    onClick={() => this.handleCreateCommand('DELETECONTAINER')}
                                                                    disabled={disabledConditions.indexOf(powerStatus) > -1}
                                                                    active={disabledConditions.indexOf(powerStatus) < 0}>Delete Container</Button>
                                                                <div className="clearfix" />
                                                                
                                                                <Col md={12}/>
<br></br>
                                                                <Col md={6}>
                                                                    <FormGroup>
                                                                        <ControlLabel>URL</ControlLabel>
                                                                        <FormControl type="text" defaultValue={this.state.manageContainerURL}
                                                                            disabled={disabledConditions.indexOf(powerStatus) > -1}
                                                                            onChange={this.handleManageContainerURLChange} />
                                                                        {showCommandHelpBlock &&
                                                                            <HelpBlock>Invalid value</HelpBlock>
                                                                        }
                                                                    </FormGroup>
                                                                </Col>
                                                                <span className="pull-right">&nbsp;</span>
                                                                <Button bsStyle="warning" bsSize="small" className="btn-fill pull-right"
                                                                    onClick={() => this.handleCreateCommand('INSTALLCONTAINER')}
                                                                    disabled={disabledConditions.indexOf(powerStatus) > -1}
                                                                    active={disabledConditions.indexOf(powerStatus) < 0}>Install Container</Button>
                                                                <div className="clearfix" />
                                                            </ListGroupItem>

                                                            

                                                            <ListGroupItem>
                                                                <h3>Set Commands</h3>
                                                                <Col md={6}>
                                                                  <FormGroup>
                                                                      <ControlLabel>Command</ControlLabel>
                                                                      <FormControl ng-model="containerSetCommands" componentClass="select" 
                                                                        onChange={this.handleContainerSetCommandStatusChange} defaultValue={containerSetCommand}>
                                                                      {
                                                                            containerSetCommands.map(command => {
                                                                          return (
                                                                            <option value={command.name}>{command.name}</option>
                                                                          );
                                                                        })
                                                                      }
                                                                      </FormControl>
                                                                  </FormGroup>
                                                                </Col>
                                                                <div className="clearfix" />
                                                                  <p class="info" >&nbsp;&nbsp;&nbsp; { 
                                                                    containerSetCommands.map(command => {
                                                                      return ( command.name == containerSetCommand ? command.description : '');
                                                                    })}
                                                                  </p><br/>
                                                                  <div className="clearfix" />

                                                                <Col md={6}>
                                                                    <FormGroup>
                                                                        <ControlLabel>Value parameter</ControlLabel>
                                                                        <FormControl type="text" defaultValue={this.state.containerSetCommandParameterValue}
                                                                            disabled={disabledConditions.indexOf(powerStatus) > -1}
                                                                            onChange={this.handleContainerSetCommandParameterValueChange} />
                                                                        {showCommandHelpBlock &&
                                                                            <HelpBlock>Invalid value</HelpBlock>
                                                                        }
                                                                    </FormGroup>
                                                                </Col>
                                                                <span className="pull-right">&nbsp;</span>
                                                                <Button bsStyle="warning" bsSize="small" className="btn-fill pull-right"
                                                                    onClick={() => this.handleCreateCommand('CONTAINERSETCOMMAND')}
                                                                    disabled={disabledConditions.indexOf(powerStatus) > -1}
                                                                    active={disabledConditions.indexOf(powerStatus) < 0}>Set</Button>
                                                                <div className="clearfix" />
                                                            </ListGroupItem>

                                                            <ListGroupItem>
                                                                <h3>Get commands</h3>

                                                                <Col md={6}>
                                                                  <FormGroup>
                                                                    <ControlLabel>Command</ControlLabel>
                                                                    <FormControl ng-model="containerGetCommands" componentClass="select" 
                                                                      onChange={this.handleContainerGetCommandStatusChange} defaultValue={containerGetCommand}>
                                                                    {
                                                                          containerGetCommands.map(command => {
                                                                        return (
                                                                          <option value={command.name}>{command.name}</option>
                                                                        );
                                                                      })
                                                                    }
                                                                    </FormControl>
                                                                  </FormGroup>
                                                                </Col>

                                                                <span className="pull-right">&nbsp;</span>
                                                                <Button bsStyle="warning" bsSize="small" className="btn-fill pull-right"
                                                                onClick={() => this.handleCreateCommand('CONTAINERGETCOMMAND')}
                                                                disabled={disabledConditions.indexOf(powerStatus) > -1}
                                                                active={disabledConditions.indexOf(powerStatus) < 0}>Get</Button>
                                                                <div className="clearfix" />
                                                                  <p class="info" >&nbsp;&nbsp;&nbsp; { 
                                                                    containerGetCommands.map(command => {
                                                                      return ( command.name == containerGetCommand ? command.description : '');
                                                                    })}
                                                                  </p>

                                                            </ListGroupItem>


                                                            <ListGroupItem>
                                                                <Col md={12}>
                                                                    <FormGroup>
                                                                        <ControlLabel>Response</ControlLabel>
                                                                        <Button bsSize="small" className="btn-fill pull-right" onClick={this.clearResponse}>Clear</Button>

                                                                    </FormGroup>
                                                                </Col>
                                                                <pre>{  this.formatJson(this.state.meta)} </pre>
                                                                <div className="clearfix" />
                                                            </ListGroupItem>



                                                        </ListGroup>








                                                    </div>
                                                }
                                            />



                                        </Col>
                                    </Row>

                                </Tab>

{/*
  Mica Client Commands Tab
*/}
                                <Tab eventKey={"micaClientCommands"} title="MICA Client">
                                    <Row>
                                        <Col md={12}>
                                            <Card
                                                content={
                                                    <div>
                                                        <p>Client management</p>
                                                        <ListGroup>

                                                            <ListGroupItem>
                                                                <h3>Login</h3>

                                                                <div className="clearfix" />
                                                            </ListGroupItem>
                                                        </ListGroup>

                                                        <ListGroupItem>
                                                                <h3>Get Clients</h3>

                                                                <Col md={6}>
                                                                </Col>

                                                                <span className="pull-right">&nbsp;</span>
                                                                <Button bsStyle="warning" bsSize="small" className="btn-fill pull-right"
                                                                onClick={() => this.handleCreateCommand('GETCLIENTSCOMMAND')}
                                                                disabled={disabledConditions.indexOf(powerStatus) > -1}
                                                                active={disabledConditions.indexOf(powerStatus) < 0}>Get</Button>
                                                                <div className="clearfix" />

                                                            </ListGroupItem>

                                                        
                                                    </div>
                                                }
                                            />



                                        </Col>
                                    </Row>
                                </Tab>


{/*
  Commands log Tab
*/}
                                <Tab eventKey={"commandLogs"} title="Command log">
                                    <Row>
                                      <Col md={12}>
                                        <Card title="Command Log"
                                          content={
                                            <form onSubmit={this.handleCommandSubmit}>
                                              <Col md={12}>
                                                <FormGroup>
                                                  <ControlLabel>Search by Command Status</ControlLabel>
                                                  <FormControl componentClass="select" placeholder={commandStatus}
                                                    onChange={this.handleCommandStatusChange} defaultValue={commandStatus}>
                                                    <option value="">All</option>
                                                    <option value="success">Success</option>
                                                    <option value="failed">Failed</option>
                                                    <option value="pending">Pending</option>
                                                  </FormControl>
                                                </FormGroup>
                                              </Col>
                                              <Button bsStyle="warning" bsSize="small" className="btn-fill pull-right" active type="submit">Search</Button>
                                              <div className="clearfix" />
                                            </form>
                                          }
                                        />
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col md={12}>
                                        { !isMinimized &&
                                        <Card
                                          ctTableFullWidth
                                          ctTableResponsive
                                          content={
                                            <Table striped hover>
                                              <thead>
                                                <tr>
                                                  {
                                                    commandThArray.map((prop, key) => {
                                                      return (
                                                        <th key={key}>{prop}</th>
                                                      );
                                                    })
                                                  }
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {/* If there is no command, showing no command found */}
                                                { commands.length === 0 && !loadingCommand && !commandHasMore &&
                                                  <tr>
                                                    <td colSpan="4" align="center">No command found.</td>
                                                  </tr>
                                                }
                                                { commands.map(command => {
                                                    return (
                                                      <tr key={command.commandId}>
                                                        <td key={command.commandId}>
                                                          <Button bsStyle="info" bsSize="small" className="btn-fill btn-round"
                                                              onClick={() => this.handleCommandDetailShow(command.deviceId, command.commandId)}>
                                                              {command.details.command} / {command.details.value}
                                                          </Button>                                          
                                                        </td>
                                                        <td key={command.status+command.commandId}>
                                                        { command.status === 'success' &&
                                                          <Label bsStyle="info">{command.status}</Label>
                                                        }
                                                        { command.status === 'failed' &&
                                                          <Label bsStyle="danger">{command.status}</Label>
                                                        }
                                                        { command.status === 'pending' &&
                                                          <Label>{command.status}</Label>
                                                        }
                                                        </td>
                                                        <td key={"createdAt"+command.commandId}>{command.createdAt}</td>
                                                        <td key={"updatedAt"+command.commandId}>{command.updatedAt}</td>
                                                      </tr>
                                                    );
                                                  })
                                                }
                                              </tbody>
                                            </Table>
                                          }
                                        />
                                        }
                                        { isMinimized &&
                                        <Card
                                          ctTableFullWidth
                                          ctTableResponsive
                                          content={
                                            <div className="custom_div">
                                            {/* If there is no command, showing no command found */}
                                            { commands.length === 0 && !loadingCommand && !commandHasMore &&
                                              <div className="custom_list_item">
                                                <span className="no_result">No command found.</span>
                                              </div>
                                            }
                                            { commands.map(command => {
                                                return (
                                                  <div className="custom_list_item" key={command.commandId} 
                                                    onClick={() => this.handleCommandDetailShow(command.deviceId, command.commandId)}>
                                                    <span className="custom_mobile_date">
                                                      <span className="mobile_date">C: {this.props.handleDateSize(command.createdAt)}</span>
                                                    </span>                                    
                                                    { command.status === 'success' &&
                                                      <Label bsStyle="info" className="custom_mobile_message">{command.status}</Label>
                                                    }
                                                    { command.status === 'failed' &&
                                                      <Label bsStyle="danger"className="custom_mobile_message">{command.status}</Label>
                                                    }
                                                    { command.status === 'pending' &&
                                                      <Label className="custom_mobile_message">{command.status}</Label>
                                                    }
                                                    <span className="custom_mobile_date">
                                                      <span className="mobile_date">U: {this.props.handleDateSize(command.updatedAt)}</span>
                                                    </span>
                                                    <span className="custom_mobile_title">
                                                      <span className="mobile_title">{command.details.command} / {command.details.value}</span>
                                                    </span>                                    
                                                  </div>
                                                );
                                              })
                                            }
                                            </div>
                                          }
                                        />
                                        }
                                      </Col>
                                    </Row>
                                    { loadingCommand &&
                                    <Row>
                                      <Col md={12}>
                                        <div>
                                          <ProgressBar active now={50} />
                                        </div>
                                      </Col>
                                    </Row>
                                  }
                                  { commandError &&
                                    <Row>
                                      <Col md={12}>
                                        <Alert bsStyle="danger">
                                          <span>{this.state.commandError}</span>
                                        </Alert>
                                      </Col>
                                    </Row>
                                  }

                                </Tab>




{/*
  Commands Tab
*/} { device && deviceStatus && (deviceStatus.deviceType !== "MICA") &&
                  <Tab eventKey={"commands"} title="Commands">
                    <Row>
                      <Col md={12}>
                        <Card
                          content={
                            <div>
                              <p>Issue Remote Command</p>
                              <ListGroup>
                                <ListGroupItem>
                                  <h3>Mode</h3>
                                  <Col md={12}>
                                    <FormGroup>
                                      <ControlLabel>Current Mode</ControlLabel>
                                      <FormControl type="text" defaultValue={this.state.powerStatus} disabled />
                                    </FormGroup>
                                  </Col>
                                  <div>
                                    <Button className="btn-fill pull-right" bsSize="small" id="OFF"
                                      onClick={() => this.handleCreateCommand('OFF')}
                                      disabled={powerStatus === 'OFF' || disabledConditions.indexOf(powerStatus) > -1}
                                      active={disabledConditions.indexOf(powerStatus) < 0}>OFF</Button>
                                    <span className="pull-right">&nbsp;</span>
                                    <Button bsStyle="primary" bsSize="small" id="AC" className="btn-fill pull-right"
                                      onClick={() => this.handleCreateCommand('AC')}
                                      disabled={powerStatus === 'AC' || disabledConditions.indexOf(powerStatus) > -1}
                                      active={disabledConditions.indexOf(powerStatus) < 0}>AC</Button>
                                    <span className="pull-right">&nbsp;</span>
                                    <Button bsStyle="danger" bsSize="small" id="HEAT" className="btn-fill pull-right"
                                      onClick={() => this.handleCreateCommand('HEAT')}
                                      disabled={powerStatus === 'HEAT' || disabledConditions.indexOf(powerStatus) > -1}
                                      active={disabledConditions.indexOf(powerStatus) < 0}>HEAT</Button>
                                    <div className="clearfix" />
                                  </div>
                                </ListGroupItem>
                                <ListGroupItem>
                                  <h3>Temperature (&#8457;)</h3>
                                  <Col md={6}>
                                    <FormGroup>
                                      <ControlLabel>Actual Temperature</ControlLabel>
                                      <FormControl type="number" defaultValue={this.state.actualTemperature} disabled />
                                    </FormGroup>
                                  </Col>
                                  <Col md={6}>
                                    <FormGroup validationState={this.state.targetTemperatureState}>
                                      <ControlLabel>Target Temperature</ControlLabel>
                                      <FormControl type="number" step="0.01" defaultValue={this.state.targetTemperature}
                                        disabled={disabledConditions.indexOf(powerStatus) > -1}
                                        onChange={this.handleTargetTemperatureChange} />
                                      { showCommandHelpBlock &&
                                        <HelpBlock>Invalid value</HelpBlock>
                                      }
                                    </FormGroup>
                                  </Col>
                                  <Button bsStyle="warning" bsSize="small" className="btn-fill pull-right"
                                    onClick={() => this.handleCreateCommand('TEMPERATURE')}
                                    disabled={disabledConditions.indexOf(powerStatus) > -1}
                                    active={disabledConditions.indexOf(powerStatus) < 0}>Set Temperature</Button>
                                  <div className="clearfix" />
                                </ListGroupItem>
                              </ListGroup>
                            </div>
                          }
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <Card title="Command Log"
                          content={
                            <form onSubmit={this.handleCommandSubmit}>
                              <Col md={12}>
                                <FormGroup>
                                  <ControlLabel>Search by Command Status</ControlLabel>
                                  <FormControl componentClass="select" placeholder={commandStatus}
                                    onChange={this.handleCommandStatusChange} defaultValue={commandStatus}>
                                    <option value="">All</option>
                                    <option value="success">Success</option>
                                    <option value="failed">Failed</option>
                                    <option value="pending">Pending</option>
                                  </FormControl>
                                </FormGroup>
                              </Col>
                              <Button bsStyle="warning" bsSize="small" className="btn-fill pull-right" active type="submit">Search</Button>
                              <div className="clearfix" />
                            </form>
                          }
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        { !isMinimized &&
                        <Card
                          ctTableFullWidth
                          ctTableResponsive
                          content={
                            <Table striped hover>
                              <thead>
                                <tr>
                                  {
                                    commandThArray.map((prop, key) => {
                                      return (
                                        <th key={key}>{prop}</th>
                                      );
                                    })
                                  }
                                </tr>
                              </thead>
                              <tbody>
                                {/* If there is no command, showing no command found */}
                                { commands.length === 0 && !loadingCommand && !commandHasMore &&
                                  <tr>
                                    <td colSpan="4" align="center">No command found.</td>
                                  </tr>
                                }
                                { commands.map(command => {
                                    return (
                                      <tr key={command.commandId}>
                                        <td key={command.commandId}>
                                          <Button bsStyle="info" bsSize="small" className="btn-fill btn-round"
                                              onClick={() => this.handleCommandDetailShow(command.deviceId, command.commandId)}>
                                              {command.details.command} / {command.details.value}
                                          </Button>                                          
                                        </td>
                                        <td key={command.status+command.commandId}>
                                        { command.status === 'success' &&
                                          <Label bsStyle="info">{command.status}</Label>
                                        }
                                        { command.status === 'failed' &&
                                          <Label bsStyle="danger">{command.status}</Label>
                                        }
                                        { command.status === 'pending' &&
                                          <Label>{command.status}</Label>
                                        }
                                        </td>
                                        <td key={"createdAt"+command.commandId}>{command.createdAt}</td>
                                        <td key={"updatedAt"+command.commandId}>{command.updatedAt}</td>
                                      </tr>
                                    );
                                  })
                                }
                              </tbody>
                            </Table>
                          }
                        />
                        }
                        { isMinimized &&
                        <Card
                          ctTableFullWidth
                          ctTableResponsive
                          content={
                            <div className="custom_div">
                            {/* If there is no command, showing no command found */}
                            { commands.length === 0 && !loadingCommand && !commandHasMore &&
                              <div className="custom_list_item">
                                <span className="no_result">No command found.</span>
                              </div>
                            }
                            { commands.map(command => {
                                return (
                                  <div className="custom_list_item" key={command.commandId} 
                                    onClick={() => this.handleCommandDetailShow(command.deviceId, command.commandId)}>
                                    <span className="custom_mobile_date">
                                      <span className="mobile_date">C: {this.props.handleDateSize(command.createdAt)}</span>
                                    </span>                                    
                                    { command.status === 'success' &&
                                      <Label bsStyle="info" className="custom_mobile_message">{command.status}</Label>
                                    }
                                    { command.status === 'failed' &&
                                      <Label bsStyle="danger"className="custom_mobile_message">{command.status}</Label>
                                    }
                                    { command.status === 'pending' &&
                                      <Label className="custom_mobile_message">{command.status}</Label>
                                    }
                                    <span className="custom_mobile_date">
                                      <span className="mobile_date">U: {this.props.handleDateSize(command.updatedAt)}</span>
                                    </span>
                                    <span className="custom_mobile_title">
                                      <span className="mobile_title">{command.details.command} / {command.details.value}</span>
                                    </span>                                    
                                  </div>
                                );
                              })
                            }
                            </div>
                          }
                        />
                        }
                      </Col>
                    </Row>
                    { loadingCommand &&
                    <Row>
                      <Col md={12}>
                        <div>
                          <ProgressBar active now={50} />
                        </div>
                      </Col>
                    </Row>
                  }
                  { commandError &&
                    <Row>
                      <Col md={12}>
                        <Alert bsStyle="danger">
                          <span>{this.state.commandError}</span>
                        </Alert>
                      </Col>
                    </Row>
                  }
                  </Tab>
    }
{/*
  Event Log Tab
*/}
                  <Tab eventKey={'logs'} title="Event Log">
                    <Row>
                      <Col md={12}>
                        <Card
                          title="Event Log"
                          content={
                            <form onSubmit={this.handleEventLogsSubmit}>
                              <Col md={12}>
                                <FormGroup>
                                  <ControlLabel>Search by Event Type</ControlLabel>
                                  <FormControl componentClass="select" placeholder={eventType}
                                    onChange={this.handleEventTypeChange} defaultValue={eventType}>
                                    <option value="">All</option>
                                    <option value="error">Error</option>
                                    <option value="warning">Warning</option>
                                    <option value="info">Info</option>
                                    <option value="diagnostic">Diagnostic</option>
                                  </FormControl>
                                </FormGroup>
                              </Col>
                              <Button bsStyle="warning" bsSize="small" className="btn-fill pull-right" active type="submit">Search</Button>
                              <div className="clearfix" />
                            </form>
                          }
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        { !isMinimized &&
                        <Card
                          ctTableFullWidth
                          ctTableResponsive
                          content={
                            <Table striped hover>
                              <thead>
                                <tr>
                                  {
                                    eventThArray.map((prop, key) => {
                                      return (
                                        <th key={key}>{prop}</th>
                                      );
                                    })
                                  }
                                </tr>
                              </thead>
                              <tbody>
                                {/* If there is no event, showing no event found */}
                                { events.length === 0 && !loadingEventLogs && !eventLogsHasMore &&
                                  <tr>
                                    <td colSpan="3" align="center">No event found.</td>
                                  </tr>
                                }
                                { events.map(event => {
                                    return (
                                      <tr key={event.id}>
                                        <td key={event.id} className="list_message_td">
                                        <Button bsStyle="info" bsSize="small" className="btn-fill btn-round custom_btn"
                                          onClick={() => this.handleEventDetailShow(event.deviceId, event.id)}>
                                            {event.message}
                                        </Button>
                                        </td>
                                        <td key={event.id+event.type} className="custom_event_type_td">
                                        {event.type === 'info' &&
                                          <Label bsStyle="info">{event.type}</Label>
                                        }
                                        {event.type === 'warning' &&
                                          <Label bsStyle="warning">{event.type}</Label>
                                        }
                                        {event.type === 'error' &&
                                          <Label bsStyle="danger">{event.type}</Label>
                                        }
                                        {event.type === 'diagnostic' &&
                                          <Label bsStyle="default">{event.type}</Label>
                                        }
                                        </td>
                                        <td key={event.id+event.createdAt} className="custom_date_td">
                                          {event.createdAt}
                                        </td>
                                      </tr>
                                    );
                                  })
                                }
                              </tbody>
                            </Table>
                          }
                        />
                        }
                        { isMinimized &&
                        <Card
                          ctTableFullWidth
                          ctTableResponsive
                          content={
                            <div className="custom_div">
                            {/* If there is no event, showing no event found */}
                            { events.length === 0 && !loadingEventLogs && !eventLogsHasMore &&
                              <div className="custom_list_item">
                                <span className="no_result">No event found.</span>
                              </div>
                            }
                            { events.map(event => {
                                return (
                                  <div className="custom_list_item" key={event.id} 
                                    onClick={() => this.handleEventDetailShow(event.deviceId, event.id)}>
                                    <span className="custom_mobile_date">
                                      <span className="mobile_date">{this.props.handleDateSize(event.createdAt)}</span>
                                    </span>
                                    <span className="custom_mobile_title">
                                      <span className="mobile_title">&nbsp;</span>
                                    </span>
                                    { event.type === 'info' &&
                                      <Label bsStyle="info" className="custom_mobile_message">
                                        <span className="mobile_message">{event.message}</span>
                                      </Label>
                                    }
                                    { event.type === 'warning' &&
                                      <Label bsStyle="warning" className="custom_mobile_message">
                                        <span className="mobile_message">{event.message}</span>
                                      </Label>
                                    }
                                    { event.type === 'error' &&
                                      <Label bsStyle="danger" className="custom_mobile_message">
                                        <span className="mobile_message">{event.message}</span>
                                      </Label>
                                    }
                                    { event.type === 'diagnostic' &&
                                      <Label bsStyle="default" className="custom_mobile_message">
                                        <span className="mobile_message">{event.message}</span>
                                      </Label>
                                    }
                                  </div>
                                );
                              })
                            }
                            </div>
                          }
                        />
                        }
                      </Col>
                    </Row>
                    { loadingEventLogs &&
                    <Row>
                      <Col md={12}>
                        <div>
                          <ProgressBar active now={50} />
                        </div>
                      </Col>
                    </Row>
                    }
                    { eventLogsError &&
                    <Row>
                      <Col md={12}>
                        <Alert bsStyle="danger">
                          <span>{this.state.eventLogsError}</span>
                        </Alert>
                      </Col>
                    </Row>
                    }
                  </Tab>
                </Tabs>
              </Col>
            </Row>
            }
          </div>
        }
        { (loadingDevice || loadingStatus) &&
          <Row>
            <Col md={12}>
              <div>
                <ProgressBar active now={50} />
              </div>
            </Col>
          </Row>
        }
        { deviceError &&
          <Row>
            <Col md={12}>
              <Alert bsStyle="danger">
                <span>Device Error: {this.state.deviceError}</span>
              </Alert>
            </Col>
          </Row>
        }
        { statusError &&
          <Row>
            <Col md={12}>
              <Alert bsStyle="danger">
                <span>Status Error: {this.state.statusError}</span>
              </Alert>
            </Col>
          </Row>
        }
        </Grid>
{/*
  Modal dialog
*/}
        <Modal show={this.state.commandDetailShow} onHide={this.handleCommandDetailClose}>
          <Modal.Header closeButton>
            <Modal.Title>Command Detail</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            { commandDetailLoading &&
              <div>
                <ProgressBar active now={50} />
              </div>
            }
            { commandDetailError &&
              <Alert bsStyle="danger">
                <span>{this.state.commandDetailError}</span>
              </Alert>
            }
            { commandDetail &&
              <Table striped bordered>
                <tbody>
                  <tr>
                    <td>Command ID</td>
                    <td>{commandDetail.commandId}</td>
                  </tr>
                  <tr>
                    <td>Device ID</td>
                    <td>{commandDetail.deviceId}</td>
                  </tr>
                  <tr>
                    <td>Command Status</td>
                    <td>{commandDetail.status}</td>
                  </tr>
                  <tr>
                    <td>Reason</td>
                    <td>{commandDetail.reason}</td>
                  </tr>
 

                           
                            <tr>
                                <td>Meta</td>
                                <td >{
                                    Object.keys(commandDetailMeta).map(key => {
                                        var dat = JSON.stringify(commandDetailMeta[key], null, 2);
                                        //dat = dat.replace(/\n/g,'<div><br/></div>');
                                        //var dat2 = dat.replace(/xxx/gi, "&#8203<wbr>");
                                        return (
                                            <div key={key + commandDetailMeta[key]}>

                                                <Label bsStyle="info">{key}</Label>&nbsp;<table class="wrap"><pre id='json'>{dat}</pre></table>
                                        </div>
                                       )
                                    })
                               }</td>
                            </tr>
                            <tr>

                    <td>Created At</td>
                    <td>{commandDetail.createdAt}</td>
                  </tr>
                  <tr>
                    <td>Updated At</td>
                    <td>{commandDetail.updatedAt}</td>
                  </tr>
                  <tr>
                    <td>Details</td>
                    <td>
                      {
                        Object.keys(commandDetail.details).map(key => {
                          return(
                            <div key={key+commandDetail[key]}>
                              <Label bsStyle="info">{key}</Label>&nbsp;<span>{commandDetail.details[key]}</span>
                            </div>
                          )
                        })
                      }
                    </td>
                  </tr>
                </tbody>
              </Table>
            }
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn-fill" onClick={this.handleCommandDetailClose}>Close</Button>
          </Modal.Footer>
        </Modal>
        <Modal show={this.state.commandShow} onHide={this.handleCommandClose}>
          <Modal.Header closeButton>
            <Modal.Title>Device Command</Modal.Title>
          </Modal.Header>
          { setCommand === 'delete-container' &&
            <div>
            <Modal.Body>Are you sure to {message}? Please insert Container name again!
                <FormControl type="text"  
                  onChange={this.handleSafetyQueryContainerNameChange} 
                                                                            />
                {showCommandHelpBlock &&
                    <HelpBlock>Invalid value</HelpBlock>
                }
            </Modal.Body>
            <Modal.Footer>
              <Button className="btn-fill" onClick={this.handleCommandClose}>Close</Button>
              <Button bsStyle="danger" className="btn-fill" active onClick={() => this.createCommand()}>Delete</Button>
            </Modal.Footer>
            </div>
          }
          { setCommand !== 'delete-container' &&
            <div>
            <Modal.Body>Are you sure to {message}?</Modal.Body>
            <Modal.Footer>
                <Button className="btn-fill" onClick={this.handleCommandClose}>Close</Button>
                <Button bsStyle="warning" className="btn-fill" active onClick={() => this.createCommand()}>Confirm</Button>
            </Modal.Footer>
            </div>
          }

          { creatingCommand &&
            <div>
              <ProgressBar active now={50} />
            </div>
          }
        </Modal>
        <Modal show={this.state.eventDetailShow} onHide={this.handleEventDetailClose}>
          <Modal.Header closeButton>
            <Modal.Title>Event Detail</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            { eventDetailLoading &&
              <div>
                <ProgressBar active now={50} />
              </div>
            }
            { eventDetailError &&
              <Alert bsStyle="danger">
                <span>{this.state.eventDetailError}</span>
              </Alert>
            }
            { eventDetail &&
              <Table striped bordered>
                <tbody>
                  <tr>
                    <td>ID</td>
                    <td>{eventDetail.id}</td>
                  </tr>
                  <tr>
                    <td>Device ID</td>
                    <td>{eventDetail.deviceId}</td>
                  </tr>
                  <tr>
                    <td>Message</td>
                    <td>{eventDetail.message}</td>
                  </tr>
                  <tr>
                    <td>Details</td>
                    <td>
                      {
                        Object.keys(eventDetail.details).map(key => {
                          return(
                            <div key={key+eventDetail.details[key]}>
                              <Label bsStyle="info">{key}</Label>&nbsp;<span>{eventDetail.details[key]}</span>
                            </div>
                          )
                        })
                      }
                    </td>
                  </tr>
                  <tr>
                    <td>Type</td>
                    <td>{eventDetail.type}</td>
                  </tr>
                  <tr>
                    <td>Created At</td>
                    <td>{eventDetail.createdAt}</td>
                  </tr>
                  <tr>
                    <td>Sent At</td>
                    <td>{eventDetail.sentAt}</td>
                  </tr>
                  <tr>
                    <td>Updated At</td>
                    <td>{eventDetail.updatedAt}</td>
                  </tr>
                  <tr>
                    <td>Acknowledged</td>
                    <td>{eventDetail.ack ? "Read" : "Unread"}</td>
                  </tr>
                </tbody>
              </Table>
            }
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn-fill" onClick={this.handleEventDetailClose}>Close</Button>
          </Modal.Footer>
        </Modal>
        { device &&
        <Row>
          <Col md={12}>
            <Button className="btn-fill pull-right" bsSize="small" onClick={this.props.goTop}>Top</Button>
          </Col>
        </Row>
        }
      </div>
    )
  }
}

/*<FormGroup>
<ControlLabel>Other commands</ControlLabel>
<FormControl ng-model="otherCommands" componentClass="select" placeholder={commandStatus}
  onChange={this.handleOtherCommandStatusChange} defaultValue={commandStatus}>
<option value=''> </option>
{
  otherCommands.map(command => {
    return (
      <option value={command.name}>{command.name}</option>
    );
  })
}
  
</FormControl>
</FormGroup>*/

/*

                                                            <ListGroupItem>
                                                                <h3>Get Configuration</h3>
                                                                <Col md={6}>
                                                                    <FormGroup>
                                                                        <ControlLabel>Name</ControlLabel>
                                                                        <FormControl type="text" defaultValue={this.state.getContainerConfigName}
                                                                            disabled={disabledConditions.indexOf(powerStatus) > -1}
                                                                            onChange={this.handleGetContainerConfigNameChange} />
                                                                        {showCommandHelpBlock &&
                                                                            <HelpBlock>Invalid value</HelpBlock>
                                                                        }

                                                                     </FormGroup>
                                                                </Col>
                                                                <div>
                                                                    <Button bsStyle="warning" className="btn-fill pull-right" bsSize="small" id="GetConfiguration"
                                                                        onClick={() => this.handleCreateCommand('GETCONTAINERCONFIG')}
                                                                        disabled={disabledConditions.indexOf(powerStatus) > -1}
                                                                        active={disabledConditions.indexOf(powerStatus) < 0}>Get Configuration</Button>
                                                                    <span className="pull-right">&nbsp;</span>
                                                                    <div className="clearfix" />
                                                                </div>
                                                            </ListGroupItem>


*/


export default DeviceDetail;
