import React, { Component } from "react";


class SIC extends Component {
  componentDidMount(){
    window.location.replace('https://sic.software')
  }


  render() {
      return ("")
  }

}


export default SIC;